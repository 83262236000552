import { createDCHook, createMutationHook, collapseDataFromCall, collapseParams } from '../client';
import {
  calculator,
  getOfferProducts,
  getOfferProductsV3,
  getProductTypeCombinations,
  putProductsOrder,
  putProductsOrderV2,
  submitLeadPublic,
  getLeadAppointments,
  createLeadAppointment,
  getExternalHeatpumpProducts,
  getExternalHeatpumpProductById,
  submitBusinessLead,
  submitCommercialOpportunity,
  getInterruptions,
  getDiscontinueReasons,
} from '../services/ProductsPublicService';

export const useProductsPublicCalculator = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(calculator, 'requestBody', 'data'),
      'data',
      'existingProducts',
      'absentProducts',
      'productTypes',
      'manualOverrides',
      'ignore',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['existingProducts', 'absentProducts', 'productTypes', 'manualOverrides', 'ignore'] },
    ],
  },
);

export const useProductsPublicGetOfferProducts = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(getOfferProducts, 'requestBody', 'data'),
      'data',
      'postalCode',
      'houseNumber',
      'houseNumberSuffix',
      'gasValue',
      'electricityNormalValue',
      'electricityLowValue',
      'electricityRedeliveryHigh',
      'electricityRedeliveryLow',
      'electricityRedeliveryTotal',
      'warmthElectricity',
      'warmthWater',
      'isMkb',
      'offerType',
      'voucherCode',
      'campaignCode',
      'actionCode',
      'products',
      'contractStartDate',
      'includeRentalProducts',
      'urgent',
      'ci',
      'movingCustomer',
      'isResidence',
      'allowDynamicOrHybridOnly',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'postalCode',
          'houseNumber',
          'houseNumberSuffix',
          'gasValue',
          'electricityNormalValue',
          'electricityLowValue',
          'electricityRedeliveryHigh',
          'electricityRedeliveryLow',
          'electricityRedeliveryTotal',
          'warmthElectricity',
          'warmthWater',
          'isMkb',
          'offerType',
          'voucherCode',
          'campaignCode',
          'actionCode',
          'products',
          'contractStartDate',
          'includeRentalProducts',
          'urgent',
          'ci',
          'movingCustomer',
          'isResidence',
          'allowDynamicOrHybridOnly',
        ],
      },
    ],
  },
);

export const useProductsPublicGetOfferProductsV3 = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(getOfferProductsV3, 'requestBody', 'data'),
      'data',
      'address',
      'usages',
      'isMkb',
      'offerType',
      'voucherCode',
      'campaignCode',
      'actionCode',
      'products',
      'preferredContractStartDate',
      'includeRentalProducts',
      'urgent',
      'movingCustomer',
      'isResidence',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'address',
          'usages',
          'isMkb',
          'offerType',
          'voucherCode',
          'campaignCode',
          'actionCode',
          'products',
          'preferredContractStartDate',
          'includeRentalProducts',
          'urgent',
          'movingCustomer',
          'isResidence',
        ],
      },
    ],
  },
);

export const useProductsPublicGetProductTypeCombinations = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(getProductTypeCombinations, 'requestBody', 'data'),
      'data',
      'postalCode',
      'houseNumber',
      'houseNumberSuffix',
      'gasValue',
      'electricityNormalValue',
      'electricityLowValue',
      'electricityRedeliveryHigh',
      'electricityRedeliveryLow',
      'electricityRedeliveryTotal',
      'warmthElectricity',
      'warmthWater',
      'isMkb',
      'offerType',
      'voucherCode',
      'campaignCode',
      'actionCode',
      'products',
      'contractStartDate',
      'includeRentalProducts',
      'urgent',
      'ci',
      'movingCustomer',
      'isResidence',
      'allowDynamicOrHybridOnly',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'postalCode',
          'houseNumber',
          'houseNumberSuffix',
          'gasValue',
          'electricityNormalValue',
          'electricityLowValue',
          'electricityRedeliveryHigh',
          'electricityRedeliveryLow',
          'electricityRedeliveryTotal',
          'warmthElectricity',
          'warmthWater',
          'isMkb',
          'offerType',
          'voucherCode',
          'campaignCode',
          'actionCode',
          'products',
          'contractStartDate',
          'includeRentalProducts',
          'urgent',
          'ci',
          'movingCustomer',
          'isResidence',
          'allowDynamicOrHybridOnly',
        ],
      },
    ],
  },
);

export const useProductsPublicPutProductsOrder = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(putProductsOrder, 'requestBody', 'data'),
      'data',
      'productOffers',
      'customer',
      'deliveryAddress',
      'processingType',
      'configuration',
      'isResidence',
      'referenceId',
      'desiredAdvancePaymentAmount',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'productOffers',
          'customer',
          'deliveryAddress',
          'processingType',
          'configuration',
          'isResidence',
          'referenceId',
          'desiredAdvancePaymentAmount',
        ],
      },
    ],
  },
);

export const useProductsPublicPutProductsOrderV2 = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(putProductsOrderV2, 'requestBody', 'data'),
      'data',
      'productOffers',
      'customer',
      'deliveryAddress',
      'processingType',
      'configuration',
      'isResidence',
      'referenceId',
      'desiredAdvancePaymentAmount',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'productOffers',
          'customer',
          'deliveryAddress',
          'processingType',
          'configuration',
          'isResidence',
          'referenceId',
          'desiredAdvancePaymentAmount',
        ],
      },
    ],
  },
);

export const useProductsPublicSubmitLeadPublic = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(submitLeadPublic, 'requestBody', 'data'),
      'data',
      'title',
      'salutation',
      'firstName',
      'surnamePrefix',
      'surname',
      'emailAddress',
      'phoneNumber',
      'address',
      'estimatedYearlyGasUsage',
      'estimatedYearlyElectricityUsage',
      'estimatedYearlyLowElectricityUsage',
      'estimatedYearlyHighElectricityUsage',
      'estimatedYearlySingleMeterElectricityUsage',
      'ownsSolarPanels',
      'numberOfSolarPanels',
      'solarPanelsProduction',
      'rentalHeatPump',
      'currentHeating',
      'currentInsulation',
      'gender',
      'solarPanelType',
      'emailOptIn',
      'measures',
      'tracking',
      'productInterests',
      'hotLead',
      'temperature',
      'supplier',
      'suppliers',
      'customer',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'title',
          'salutation',
          'firstName',
          'surnamePrefix',
          'surname',
          'emailAddress',
          'phoneNumber',
          'address',
          'estimatedYearlyGasUsage',
          'estimatedYearlyElectricityUsage',
          'estimatedYearlyLowElectricityUsage',
          'estimatedYearlyHighElectricityUsage',
          'estimatedYearlySingleMeterElectricityUsage',
          'ownsSolarPanels',
          'numberOfSolarPanels',
          'solarPanelsProduction',
          'rentalHeatPump',
          'currentHeating',
          'currentInsulation',
          'gender',
          'solarPanelType',
          'emailOptIn',
          'measures',
          'tracking',
          'productInterests',
          'hotLead',
          'temperature',
          'supplier',
          'suppliers',
          'customer',
        ],
      },
    ],
  },
);

export const useProductsPublicGetLeadAppointments = createDCHook(
  'getLeadAppointments',
  collapseDataFromCall(getLeadAppointments),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useProductsPublicCreateLeadAppointment = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(createLeadAppointment, 'requestBody', 'data'),
      'data',
      'startsAt',
      'referenceId',
      'leadRequest',
    ),
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['startsAt', 'referenceId', 'leadRequest'] },
    ],
  },
);

export const useProductsPublicGetExternalHeatpumpProducts = createDCHook(
  'getExternalHeatpumpProducts',
  collapseDataFromCall(getExternalHeatpumpProducts),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useProductsPublicGetExternalHeatpumpProductById = createDCHook(
  'getExternalHeatpumpProductById',
  collapseDataFromCall(getExternalHeatpumpProductById),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useProductsPublicSubmitBusinessLead = createMutationHook(
  collapseParams(collapseParams(submitBusinessLead, 'requestBody', 'data'), 'data', 'emailAddress'),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['emailAddress'] },
    ],
  },
);

export const useProductsPublicSubmitCommercialOpportunity = createMutationHook(
  collapseParams(
    collapseParams(submitCommercialOpportunity, 'requestBody', 'data'),
    'data',
    'companyName',
    'chamberOfCommerceNumber',
    'contactPerson',
    'deliveryAddress',
    'requestedStartDate',
    'gasEstimatedYearlyUsage',
    'electricityEstimatedYearlyUsage',
    'additionalInfo',
    'campaignCode',
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'companyName',
          'chamberOfCommerceNumber',
          'contactPerson',
          'deliveryAddress',
          'requestedStartDate',
          'gasEstimatedYearlyUsage',
          'electricityEstimatedYearlyUsage',
          'additionalInfo',
          'campaignCode',
        ],
      },
    ],
  },
);

export const useProductsPublicGetInterruptions = createDCHook(
  'getInterruptions',
  collapseDataFromCall(getInterruptions),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useProductsPublicGetDiscontinueReasons = createDCHook(
  'getDiscontinueReasons',
  collapseDataFromCall(getDiscontinueReasons),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);
